import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [];

  connect() {
    console.log("connecting yform controller");
    this.yform = this.element;
    this.initDateSelects();
    let showYForm = this.yform.querySelector("#showyformtoolbar");
    if (showYForm != undefined) {
      this.setupToolbar();
    }
  }
  initDateSelects() {
    let dateSelects = this.yform.querySelectorAll('select[type="date"]');
    for (var i = 0; i < dateSelects.length; i++) {
      let s = dateSelects[i];
      // console.log("\n\nraw date value: " + s.getAttribute("value"));
      let year = Number(s.getAttribute("value").substring(0, 4));
      let month = Number(s.getAttribute("value").substring(5, 7));
      let day = Number(s.getAttribute("value").substring(8, 10));
      // console.log(`found date ${year} - ${month} - ${day}`);
      if (s.getAttribute("name").match(/year/)) {
        // console.log("set year to " + year);
        s.value = year;
      }
      if (s.getAttribute("name").match(/month/)) {
        // console.log("set month to " + month);
        s.value = month;
      }
      if (s.getAttribute("name").match(/day/)) {
        // console.log("set day to " + day);
        s.value = day;
      }
      // console.log("found select " + s.getAttribute("value"));
    }
  }
  setupToolbar() {
    this.toolbar = document.createElement("div");
    this.toolbar.classList = "toolbar my-4 py-2 px-0";
    // this.toolbar.innerHTML = "<p>hello toolbar</p>";

    this.downloadButton = document.createElement("button");
    this.downloadButton.classList = "btn-sm btn-primary me-1";
    this.downloadButton.innerText = "Formulardaten speichern";
    this.downloadButton.addEventListener(
      "click",
      function (e) {
        this.serializeYform(e);
      }.bind(this),
    );

    this.uploadButton = document.createElement("button");
    this.uploadButton.classList = "btn-sm btn-primary mx-1";
    this.uploadButton.innerText = "Formulardaten hochladen";
    this.uploadButton.addEventListener(
      "click",
      function (e) {
        e.preventDefault();
        this.fileInput.click();
      }.bind(this),
    );

    this.fileInput = document.createElement("input");
    this.fileInput.type = "file";
    this.fileInput.style.display = "none";
    this.fileInput.addEventListener(
      "change",
      function (e) {
        this.uploadFormData(e);
      }.bind(this),
    );

    document.body.appendChild(this.fileInput);

    this.toolbar.append(this.downloadButton);
    this.toolbar.append(this.uploadButton);
    this.yform.prepend(this.toolbar);
  }
  serializeYform(e) {
    e.preventDefault();

    var json_obj = [];
    let fields = this.yform.querySelectorAll(
      '.form-group[id*="yform-' + this.yform.id + '"]',
    );
    // let inputs = document.querySelectorAll('[name*="FORM[' + this.yform.id + ']"]');
    console.log(
      "now serializeYform " + this.yform.id + " fields: " + fields.length,
    );

    for (var i = 0; i < fields.length; i++) {
      // console.log("\t" + fields[i].id);

      let field_inputs = fields[i].querySelectorAll("input, select, textarea");
      if (this.inputsToString(field_inputs)) {
        if (fields[i].id.match(/bdate/)) {
          console.log("bdate: " + this.inputsToString(field_inputs));
        }
        json_obj.push({
          fieldname: fields[i].id,
          value: this.inputsToArray(field_inputs),
        });
      }
    }
    this.pushJsonDownload(JSON.stringify(json_obj, null, "  "));
  }
  inputsToArray(inputs) {
    if (inputs == undefined || inputs.length == 0) {
      return false;
    }
    var values = [];
    let blacklist_input_types = ["file", "hidden"];
    let blacklist = ["", "00", "Bitte auswählen"];
    for (var i = 0; i < inputs.length; i++) {
      if (blacklist_input_types.includes(inputs[i].type)) {
        continue;
      }
      if (!blacklist.includes(inputs[i].value)) {
        values.push(inputs[i].value);
      }
    }
    return values;
  }
  inputsToString(inputs) {
    let values = this.inputsToArray(inputs);
    if (values) {
      return values.join("-");
    }
  }
  setJson(newJson) {
    newJson.replaceAll("\n", "");
    this.json = JSON.parse(newJson);
    // console.log("did set json to " + this.json);

    for (let i = 0; i < this.json.length; i++) {
      let formGroup = document.getElementById(this.json[i].fieldname);
      let field_inputs = formGroup.querySelectorAll("input, select, textarea");
      for (var j = 0; j < field_inputs.length; j++) {
        field_inputs[j].value = this.json[i].value[j];
      }
    }
    alert(
      "Die Formulardaten wurden erfolgreich geladen. Bitte beachten Sie, dass Dateien erneut hochgeladen werden müssen.",
    );
  }
  uploadFormData(e) {
    if (!e.target.value.length) {
      alert("Bitte eine gültige Datei auswählen!");
    }
    console.log("do the upload");
    let file = e.target.files[0];
    let reader = new FileReader();

    let handler = this.getReadHandlerFunction();
    reader.onerror = function (e) {
      alert("Es ist ein Fehler beim Hochladen der Datei aufgetreten.\n ");
    };
    reader.onload = handler;
    reader.readAsText(file);
  }
  getReadHandlerFunction() {
    let editor = this;
    let f = function (event) {
      let fileContent = event.target.result;
      // this.setJson(JSON.parse(fileContent));
      this.setJson(fileContent);
    }.bind(editor);
    return f;
  }

  getFileTitle() {
    var h = document.querySelector("h1, h2");
    if (h != undefined) {
      h = h.innerText.trim();
      h = h.replaceAll(/\s/gi, "_");
      h = h.replaceAll(/[^a-z0-9_]+/gi, "");
      return "_" + h + ".json";
    } else {
      return "_antrag.json";
    }
  }
  pushJsonDownload(json) {
    let dataUri =
      "data:application/json;charset=utf-8," + encodeURIComponent(json);
    let cd = new Date();
    let fn = cd.toJSON().substring(0, 19) + this.getFileTitle();

    var downloadAnchor = document.createElement("a");
    downloadAnchor.innerText = "Download";
    downloadAnchor.setAttribute("href", dataUri);
    downloadAnchor.setAttribute("download", fn);

    downloadAnchor.click();
  }
}

document.addEventListener(
  "DOMContentLoaded",
  function () {
    console.log("yform_json_serializer DOMContentLoaded");

    let forms = document.querySelectorAll("form.rex-yform");
    console.log("\tfound yforms: " + forms.length);
    for (var i = 0; i < forms.length; i++) {
      forms[i].dataset.controller = "yform";
    }
  },
  false,
);
