import ApplicationController from "./application_controller";
import EasyMDE from "easymde";

import "easymde/dist/easymde.min.css";

export default class extends ApplicationController {
  static targets = ["submitbutton", "textarea"];

  connect() {
    console.log(
      "did connect schulprofil with text " + this.textareaTarget.value,
    );
    this.editor = new EasyMDE({
      element: this.textareaTarget,
      spellChecker: false,
      toolbar: [
        "bold",
        "italic",
        "heading-3",
        "|",
        "quote",
        "unordered-list",
        "ordered-list",
        "link",
        "|",
        "undo",
        "redo",
        "|",
        "preview",
        "guide",
      ],
    });
  }
  async submit(e) {
    e.preventDefault();
    e.stopPropagation();
    let url = this.ajax_url("update_profile", "SchuleEditController");

    let response = await this.doFetch(
      url,
      "POST",
      JSON.stringify({ profile: this.editor.value() }),
    );
    if (response.ok) {
      alert("Änderungen erfolgreich gespeichert!");
    } else {
      alert(
        "Es ist ein Fehler aufgetreten: \n" +
          response.status +
          "\n" +
          response.statusText,
      );
    }
  }
}
