import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["toolbar", "list"];

  connect() {
    console.log("did connect erwsl controller");
  }
  changeperson(event) {
    event.preventDefault();
    event.stopPropagation();

    console.log("did change person");
    let sel = document.getElementById(event.target.dataset.selectid);
    let option = sel.options[sel.selectedIndex];
    let dienstbezeichnung_input = super.getYformInput("dienstbezeichnung");
    let vorname_input = super.getYformInput("erwsl_entry-vorname");
    let name_input = super.getYformInput("erwsl_entry-name");
    console.log("found input " + name_input);
    dienstbezeichnung_input.value = option.dataset.dienstbezeichnung;
    vorname_input.value = option.dataset.vorname;
    name_input.value = option.dataset.name;
  }
  async create(event) {
    let url = super.ajax_url("create", "ErwSLController");
    let body = JSON.stringify({});
    const response = await super.doFetch(url, "POST", body);
    if (response.ok) {
      super.loadControllerAction("ErwSLController", "show_index");
    } else {
      alert("Es ist ein Fehler aufgetreten! Status: " + response.status);
    }
  }
  async delete(event) {
    if (window.confirm("Möchten Sie diesen Eintrag sicher löschen?")) {
      let url = super.ajax_url("delete", "ErwSLController");
      let body = JSON.stringify({
        index: event.target.dataset.entryIndex,
      });
      const response = await super.doFetch(url, "POST", body);
      if (response.ok) {
        super.loadControllerAction("ErwSLController", "show_index");
      } else {
        alert("Es ist ein Fehler aufgetreten! Status: " + response.status);
      }
    }
    return false;
  }
  async update(event) {
    alert("update");
    let url = super.ajax_url("post", "VAsController");
    var data = {
      personen_rolle_id: event.target.dataset.personId,
      stunden: event.target.value,
      key: event.target.dataset.selectvalue,
    };

    let body = JSON.stringify(data);
    const response = await super.doFetch(url, "POST", body);
    if (response.ok) {
      event.target.insertAdjacentHTML("afterend", "<span>👍</span>");
    } else {
      event.target.insertAdjacentHTML("afterend", "<span>⚠️</span>");
      alert("Es ist ein Fehler aufgetreten! Status: " + response.status);
    }
  }
}
