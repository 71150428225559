import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["status"];

  connect() {
    console.log("did connect BSVAS");
  }
  async update(event) {
    let url = super.ajax_url("post", "VAsController");
    var data = {
      personen_rolle_id: event.target.dataset.personId,
      stunden: event.target.value,
      key: event.target.dataset.selectvalue,
    };

    let body = JSON.stringify(data);
    const response = await super.doFetch(url, "POST", body);
    if (response.ok) {
      event.target.insertAdjacentHTML("afterend", "<span>👍</span>");
    } else {
      event.target.insertAdjacentHTML("afterend", "<span>⚠️</span>");
      alert("Es ist ein Fehler aufgetreten! Status: " + response.status);
    }
  }
}
