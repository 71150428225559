/**
 * This class implements updating Jourdienste
 */
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["status"];

  connect() {
    // console.log("did connect jourdienst_controller");
  }
  async change(e) {
    e.preventDefault();
    e.stopPropagation();
    let postdata = {
      year: e.target.dataset["year"],
      week: e.target.dataset["week"],
      ferienid: e.target.dataset["ferienId"],
      jdid: e.target.dataset["jourdienstId"],
      personid: e.target.value,
      pstr: e.target.innerText,
    };
    let ajax_url = super.ajax_url("postjd", "JourDienstController");

    // alert("now submit data to " + ajax_url + JSON.stringify(postdata));
    let response = await super.doFetch(
      ajax_url,
      "POST",
      JSON.stringify(postdata),
    );
    if (response.ok) {
      this.statusTarget.innerText = "😀";
    } else {
      alert(
        "Es ist ein Fehler aufgetreten: " +
          response.status +
          "\n" +
          response.statusText,
      );
    }
  }
}
