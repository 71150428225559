import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["newfunktionselect"];
  connect() {
    console.log("did connect BsFunktionenPlanController.js");
  }

  async sendnewfunktion(event) {
    console.log("send new funktion " + this.newfunktionselectTarget.value);
    let url = super.ajax_url("createfunktion", "BSFunktionenplanController");
    let rolleid = this.newfunktionselectTarget.value;
    if (rolleid == 0 || rolleid == "0") {
      alert("Bitte wählen Sie eine Funktion aus!");
      return false;
    }
    let body = JSON.stringify({
      rolle_id: rolleid,
    });
    await this.post(url, body, event);
  }
  async removefunktion(event) {
    if (window.confirm("Soll diese Funktion wirklich gelöscht werden?")) {
      let url = super.ajax_url("remove_funktion", "BSFunktionenplanController");
      let body = JSON.stringify({
        personen_rolle_id: event.target.dataset.prid,
      });
      await this.post(url, body, event);
    }
  }
  async setbesetzung(event) {
    console.log("send besetzung " + event.target.value);
    let url = super.ajax_url("postbesetzung", "BSFunktionenplanController");
    let body = JSON.stringify({
      personen_rolle_id: event.target.dataset.prid,
      besetzungsstand: event.target.value,
    });
    await this.post(url, body, event);
  }
  async setperson(event) {
    console.log("now set person to " + event.target.dataset.rolleId);
    let url = super.ajax_url("postperson", "BSFunktionenplanController");
    let body = JSON.stringify({
      personen_rolle_id: event.target.dataset.prid,
      rolle_id: event.target.dataset.rolleId,
      person_id: event.target.value,
    });
    await this.post(url, body, event);
  }
  async addfach(event) {
    let fach_select = document.getElementById(event.target.dataset.selectId);
    console.log("send fach " + fach_select.value);

    let fach = fach_select.value;
    if (fach == 0 || fach == "0") {
      alert("Bitte wählen Sie ein Fach aus!");
      return false;
    }
    let url = super.ajax_url("add_fach", "BSFunktionenplanController");
    let body = JSON.stringify({
      personen_rolle_id: event.target.dataset.prid,
      fach: fach,
    });
    await this.post(url, body, event);
  }
  async removefach(event) {
    console.log("delete fach " + event.target.dataset.fachid);

    let url = super.ajax_url("remove_fach", "BSFunktionenplanController");
    let body = JSON.stringify({
      personen_rolle_id: event.target.dataset.prid,
      fachindex: event.target.dataset.fachindex,
    });
    await this.post(url, body, event);
  }
  async post(url, body, e) {
    const response = await super.doFetch(url, "POST", body);
    if (response.ok) {
      window.location.reload();
    } else {
      e.target.insertAdjacentHTML("afterend", "<span>⚠️</span>");
      alert("Es ist ein Fehler aufgetreten! Status: " + response.status);
    }
  }
}
