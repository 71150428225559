import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["status"];

  connect() {}

  async change(e) {
    e.preventDefault();
    e.stopPropagation();
    let postdata = {
      person_id: e.target.value,
      rolle_id: e.target.dataset["rolleId"],
    };
    let ajax_url = super.ajax_url("postrolle", "BSAufgabentraegerController");

    // alert("now submit data to " + ajax_url + JSON.stringify(postdata));
    let response = await super.doFetch(
      ajax_url,
      "POST",
      JSON.stringify(postdata),
    );
    if (response.ok) {
      this.statusTarget.innerText = "😀";
    } else {
      alert(
        "Es ist ein Fehler aufgetreten: " +
          response.status +
          "\n" +
          response.statusText,
      );
    }
  }
}
